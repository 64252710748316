<template>
  <div class="row">

    <div class="col-md-8">
      <Print
        :data="data"
        height="170mm"
        :setting="setting"
        :showSummary="true"
        :payment_slip="payment_slip"
        :items="items"
        :renderNotes="renderNotes"
      />
    </div>

    <div class="d-none">
      <Print
        v-if="items.length <= 5"
        :data="data"
        height="170mm"
        :isPrint="true"
        :setting="setting"
        :showSummary="true"
        :payment_slip="payment_slip"
        :items="items"
        :renderNotes="renderNotes"
      />
      <div
        v-if="items.length > 5"
        id="print"
      >
        <Print
          v-for="(n, index) in new Array(papers).fill(null)"
          :data="data"
          height="170mm"
          :isPrint="true"
          :key="index"
          :setting="setting"
          :showSummary="(index + 1) == itemsMultiPapers.length"
          :payment_slip="payment_slip"
          :items="itemsMultiPapers[index]"
          :renderNotes="renderNotes"
        />
      </div>
    </div>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1600"
      :filename="'struk-apotek-' + data.patient_name"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-orientation="landscape"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          v-if="items.length <= 5"
          :data="data"
          height="146.5mm"
          :setting="setting"
          :showSummary="true"
          :payment_slip="payment_slip"
          :items="items"
          :renderNotes="renderNotes"
        />
        <template v-if="items.length > 5">
          <Print
            v-for="(n, index) in new Array(papers).fill(null)"
            :data="data"
            height="146.5mm"
            :isPrint="true"
            :key="index"
            :setting="setting"
            :showSummary="(index + 1) == itemsMultiPapers.length"
            :payment_slip="payment_slip"
            :items="itemsMultiPapers[index]"
            :renderNotes="renderNotes"
          />
        </template>
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
      >Print</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Download</b-button>
      <b-button
        squared
        v-if="payment_slip.image != null"
        :class="'d-block mt-2'"
        size="sm"
        variant="warning"
        v-b-modal.modal-preview-image
      >Lihat Bukti Transfer</b-button>
      <b-button
        v-if="data.payment_status_id != 3 && payment_slip.is_last"
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-b-modal.modal-checkout
      >Edit Pembayaran</b-button>
      <b-button
        v-if="data.payment_status_id != 3"
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        @click="$router.push('/payment/edit/' + data.id)"
      >Lanjutkan Pembayaran</b-button>
    </div>

    <b-modal
      v-if="payment_slip.image != null"
      id="modal-preview-image"
      title="Bukti Transfer"
      size="lg"
      hide-footer
    >
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style='height: 350px; width: 350px; position: relative'>
          <img
            class="image"
            :src="payment_slip.image"
          >
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-custom-date"
      title="Edit tanggal kwitansi"
      size="md"
      ok-title="Print"
      cancel-title="Batal"
      @ok="modalCustomDatePurpose == 'download' ? confirmDownload() : modalCustomDatePurpose == 'print' ? confirmPrint() : null"
      @hidden="modalCustomDateOnHide"
    >
      <b-form-group
        id="input-group-custom-date"
        label="Tanggal kwitansi"
        label-for="input-custom-date"
      >
        <b-input-group>
          <b-form-datepicker
            v-model="customDate"
            id="input-custom-date"
            placeholder="Edit tanggal kwitansi"
            locale="id"
          ></b-form-datepicker>
          <template #append>
            <b-input-group-text
              squared
              :class="btnResetCustomDateClass"
              style="border-left: none"
              @click="btnResetCustomDateOnClick"
              @mouseover="btnResetCustomDateClass = 'bg-light-danger'"
              @mouseout="btnResetCustomDateClass = 'bg-white'"
            >
              <i class="fas fa-sm fa-times text-danger"></i>
            </b-input-group-text>
          </template>
        </b-input-group>
      </b-form-group>
    </b-modal>

    <ModalCheckout
      v-if="renderModalCheckout"
      :isHospitalTransaction="true"
      :form="form"
      :error="error"
      :isEditPayment="true"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @modalCheckoutOnShow="modalCheckoutOnShow"
      @confirmCheckout="confirmCheckout"
    />

  </div>
</template>

<script>

import Print from '@/component/payments/SlipPrint.vue'
import ModalCheckout from '@/component/general/ModalCheckout.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf,
    ModalCheckout
  },

  data() {
    return {
      // Data
      data: {},
      payment_slip: {},
      items: [],
      itemsMultiPapers: [],
      papers: 1,
      // form
      form: {
        payment_id: this.$route.params.id,
        payment_method_id: 1,
        payment_status_id: '',
        bank_id: '',
        bank_account_target: '',
        image: '',
        bank_account_number: '',
        bank_account_name: '',
        card_number: '',
        card_type: '',
        edc_struct: '',
        e_wallet_id: '',
        total_amount: 0,
        final_amount: 0,
        count_final_amount: 0,
        payment_amount: 0,
        change_amount: 0,
        unpaid_amount: 0,
        count_unpaid_amount: 0,
        notes: '',
      },
      error: {
        payment_id: '',
        payment_method_id: '',
        payment_status_id: '',
        bank_id: '',
        bank_account_target: '',
        image: '',
        card_number: '',
        card_type: '',
        edc_struct: '',
        bank_account_number: '',
        bank_account_name: '',
        e_wallet_id: '',
        total_amount: '',
        final_amount: '',
        payment_amount: '',
        change_amount: '',
        unpaid_amount: '',
        count_unpaid_amount: '',
        notes: '',
      },
      // custom date
      customDate: '',
      oldDefaultDate: '',
      modalCustomDatePurpose: '',
      // access management
      manipulateBtn: false,
      // settings
      setting: {},
      // other
      renderNotes: true,
      renderModalCheckout: false,
      btnResetCustomDateClass: 'bg-white'
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get('website-settings/get-default')
      if (this.setting == null) {
        this.setting = {
          address: "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          emergency: "(021) 3909333"
        }
      }
    },

    async getPayment() {
      this.data = await module.get(`payments/${this.$route.params.id}`)
      this.payment_slip = await module.get(`payment-slips/${this.$route.params.slip_id}`)
      console.info(this.payment_slip)
      this.setForm()
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/payment/list')
      }
    },

    async getPaymentDetail() {
      this.items = await module.list(`payment-details/${this.$route.params.id}`)
      this.papers = Math.ceil(this.items.length / 5)
      let start = 0
      for (let j = 0; j < this.papers; j++) {
        this.itemsMultiPapers.push(this.items.slice(start, start + 5))
        start += 5
      }
    },

    setForm() {
      this.form.payment_amount = parseInt(this.data.payment_amount).toLocaleString('id-ID')
      this.form.payment_method_id = this.payment_slip.payment_method_id
      this.form.payment_status_id = this.payment_slip.payment_status
      this.form.bank_id = this.payment_slip.bank_id
      this.form.card_number = this.payment_slip.card_number
      this.form.card_type = this.payment_slip.card_type
      this.form.edc_struct = this.payment_slip.edc_struct
      this.form.bank_account_number = this.payment_slip.bank_account_number
      this.form.bank_account_name = this.payment_slip.bank_account_name
      this.form.e_wallet_id = this.payment_slip.e_wallet_id
      this.form.total_amount = this.data.total_amount
      this.form.final_amount = parseInt(this.payment_slip.final_amount)
      this.form.count_final_amount = parseInt(this.payment_slip.count_final_amount)
      this.form.change_amount = this.data.change_amount
      this.form.unpaid_amount = this.data.unpaid_amount
      this.form.count_unpaid_amount = this.data.unpaid_amount
      this.form.notes = this.payment_slip.notes
      this.form.bank_account_target = this.payment_slip.bank_account_target
      this.renderModalCheckout = true
    },

    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error)
      this.form.payment_amount = parseInt(this.data.payment_amount).toLocaleString('id-ID')
      this.form.change_amount = 0
    },

    modalCheckoutOnShow() {
      this.error = validation.clearValidationError(this.error)
      this.form.payment_amount = parseInt(this.data.payment_amount).toLocaleString('id-ID')
      this.form.change_amount = 0
    },

    paymentMethodOnClick(id) {
      this.form.payment_method_id = id
    },

    btnShortCutOnClick(amount) {
      this.form.payment_amount = parseInt(amount).toLocaleString('id-ID')
      this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.final_amount
    },

    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id
    },

    inputCashOnKeyUp(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        if (this.form.payment_status_id == 2) {
          this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.unpaid_amount
          this.form.count_final_amount = (this.form.final_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10))
        } else {
          this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.final_amount
          this.form.unpaid_amount = this.form.final_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10)
        }
      } else {
        evt.preventDefault()
      }
    },

    async confirmCheckout() {
      if (this.form.payment_method_id != 1) {
        if (this.form.payment_amount != 0 && parseInt(this.form.payment_amount.replace(/\D/g, ''), 10) > this.form.unpaid_amount) {
          Swal.fire('Gagal', 'Nominal Pembayaran tidak boleh melebihi Total Tagihan', 'warning')
        } else {
          if (this.form.payment_amount == 0) {
            Swal.fire('Gagal', 'Nominal Pembayaran belum diisi', 'warning')
          } else {
            this.actualCheckout()
          }
        }
      } else {
        if (this.form.payment_amount == 0) {
          Swal.fire('Gagal', 'Nominal Pembayaran belum diisi', 'warning')
        } else {
          this.actualCheckout()
        }
      }
    },

    async actualCheckout() {
      this.form['_method'] = 'put'
      let form = this.form
      if (this.form.payment_method_id == 3) {
        form = new FormData()
        for (const [key, value] of Object.entries(this.form)) {
          if (key == 'payment_categories_id' || key == 'medicines_id' || key == 'lab_procedures_id') {
            form.append(key, JSON.stringify(value))
          } else {
            form.append(key, value)
          }
        }
      }
      let response = await module.submit(form, `payment-slips/${this.payment_slip.id}`)
      if (response.state == 'error') {
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: 'success',
        })
        this.getPayment()
        this.getPaymentDetail()
        this.$bvModal.hide('modal-checkout')
      }
    },

    modalCustomDateOnHide() {
      this.modalCustomDatePurpose = ''
    },

    btnResetCustomDateOnClick() {
      this.customDate = this.payment_slip.date
    },

    btnPrintOnClick() {
      this.$bvModal.show('modal-custom-date')
      this.modalCustomDatePurpose = 'print'
      this.customDate = this.payment_slip.date
    },

    confirmPrint() {
      this.oldDefaultDate = this.payment_slip.display_date
      this.payment_slip.display_date = new Date(this.customDate).toLocaleString('id-ID', { day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-')
      setTimeout(() => {
        this.renderNotes = false
        this.$htmlToPaper('print')
        this.renderNotes = true
        this.payment_slip.display_date = this.oldDefaultDate
      }, 500)
    },

    async btnDownloadOnClick() {
      this.$bvModal.show('modal-custom-date')
      this.modalCustomDatePurpose = 'download'
      this.customDate = this.payment_slip.date
    },

    confirmDownload() {
      this.oldDefaultDate = this.payment_slip.display_date
      this.payment_slip.display_date = new Date(this.customDate).toLocaleString('id-ID', { day: 'numeric', month: 'numeric', year: 'numeric' }).replaceAll('/', '-')
      setTimeout(async () => {
        this.renderNotes = false
        await this.$refs.html2Pdf.generatePdf()
        this.renderNotes = true
        this.payment_slip.display_date = this.oldDefaultDate
      }, 500)
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6002") {
          this.manipulateBtn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Pembayaran", route: "/payment/list" },
      { title: "Detail", route: `/payment/detail/${this.$route.params.id}` },
      { title: 'Bukti Bayar' }
    ])
    // Get Data
    this.getPayment()
    this.getSetting()
    this.getPaymentDetail()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>