<template>
  <div class="card" style="border: none" :id="isPrint ? 'print' : ''">
    <div class="card-body" :style="isPrint ? `height: ${height};` : ''">
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <table>
            <div style="font-size: 14pt"><strong>Kwitansi</strong></div>
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px">:</td>
              <td>{{ data.id }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px">:</td>
              <td>{{ payment_slip.display_date }}</td>
            </tr>
            <tr>
              <td>Nama Pasien</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr>
              <td>Pembayaran</td>
              <td style="width: 9px">:</td>
              <td>#{{ $route.query.terms }}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <img
            src="/images/hayandra.jpeg"
            height="60"
            style="margin-left: -10px"
          />
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ setting.address }}
          </div>
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ setting.emergency }}
          </div>
        </div>
      </div>
      <div style="min-height: 190px">
        <b-table striped hover small :items="items" :fields="fields">
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(price)="data">
            Rp
            {{
              parseInt(
                parseInt(data.item.price) -
                  parseInt(data.item.promo_amount) +
                  data.item.discount_amount
              ).toLocaleString("id-ID")
            }}
          </template>
          <template #cell(quantity)="data">
            {{ data.item.ref_table == 3 ? data.item.quantity : "" }}
          </template>
          <template #cell(discount)="data">
            <span v-if="data.item.discount_type == 0"
              >Rp
              {{
                parseInt(data.item.discount_amount).toLocaleString("id-ID")
              }}</span
            >
            <span v-if="data.item.discount_type == 1"
              >Rp
              {{
                parseInt(data.item.discount_percentage).toLocaleString("id-ID")
              }}</span
            >
          </template>
          <template #cell(subtotal)="data">
            Rp {{ parseInt(data.item.total).toLocaleString("id-ID") }}
          </template>
        </b-table>
      </div>
      <div class="d-flex justify-content-between align-items-top">
        <div class="mr-2">
          <!-- Notes -->
          <div class="form-group" v-if="renderNotes">
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="payment_slip.notes"
              readonly
            ></textarea>
          </div>
        </div>
        <table v-if="showSummary">
          <tr v-if="data.total_discount_item != 0 && typeof data.total_discount_item != 'undefined'">
            <td>Total Diskon Item</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_discount_item).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Diskon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.discount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Biaya Tambahan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.surcharge).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="data.coupon_amount">
            <td>Kupon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">
                {{ parseInt(data.coupon_amount).toLocaleString("id-ID") }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.final_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <hr style="margin: 5px" />
            </td>
            <td>
              <hr style="margin: 5px" />
            </td>
            <td>
              <hr style="margin: 5px" />
            </td>
          </tr>
          <tr>
            <td>Tagihan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.bill_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Terbayar</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.payment_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="payment_slip.change_amount > 0">
            <td>Kembalian</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.change_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-danger">Sisa</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right text-danger">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.unpaid_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 120px; padding: 1.3px">Metode Bayar</td>
            <td class="font-weight-bold" style="width: 25px"></td>
            <td class="text-right" v-if="payment_slip.payment_method_id == 2">
              {{ `${cardType} ${payment_slip.bank_name}` }}
            </td>
            <td class="text-right" v-if="payment_slip.payment_method_id != 2">
              {{
                `${payment_slip.payment_method_name}
                          ${
                            payment_slip.payment_method_id == 3
                              ? payment_slip.bank_account_name
                              : ""
                          }`
              }}
            </td>
          </tr>
          <tr v-if="payment_slip.payment_status == 3">
            <td colspan="3" class="text-center font-weight-bold">*LUNAS*</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center p-0 mt-n6">
      <div>
        <hr class="special-hr" style="margin: 2px" />
        <div>{{ currentUserPersonalInfo.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: {
    cardType: "",
  },

  props: {
    data: Object,
    setting: Object,
    height: String,
    isPrint: Boolean,
    items: Array,
    renderNotes: Boolean,
    payment_slip: Object,
    showSummary: Boolean,
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
        },
        {
          key: "quantity",
          label: "Jumlah",
          thStyle: { width: "5%" },
        },
        {
          key: "discount",
          label: "Diskon",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "price",
          label: "Harga",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "subtotal",
          label: "Subtotal",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
    };
  },

  watch: {
    payment_slip: function (newVal) {
      console.info("change", this.payment_slip.display_date);
      if (this.payment_slip.card_type == 1) {
        this.cardType = "K. Kredit";
      } else if (this.payment_slip.card_type == 2) {
        this.cardType = "K. Debit";
      }
    },
  },
};
</script>

<style scoped>
.special-hr {
  border-color: #000000;
  border-style: solid;
  border-width: 2px 0 0;
  clear: both;
  margin: 0 0 0px;
  height: 0;
}
</style>